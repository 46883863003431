<template>
  <EditAssetUtilizationCategoryStyled> Edit </EditAssetUtilizationCategoryStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const EditAssetUtilizationCategoryStyled = styled('div')``

export default {
  components: {
    EditAssetUtilizationCategoryStyled,
  },
}
</script>
